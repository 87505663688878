<template>
  <div>
    <div class="holder">
      <div class="dashboard-holder">
        <div class="small_nav_holder">
          <SmallNav />
          <!-- <div style="">
            <img
              @click.prevent="MoveToSure"
              style="width: 100%; cursor: pointer;"
              src="../assets/home/promo-banner.gif"
              alt="promo"
            />
          </div> -->
        </div>

        <div class=" top_bar_holder">
          <div class="row">
            <div
              class="col-md-6 col-lg-4 col-sm-12 col-lg-4 col-xl-4 d-none d-sm-block d-sm-none d-md-block"
            >
              <div>
                <p class="top_bar_payrent">
                  Pay My Rent
                  <img src="../assets/images/white-arrow.svg" alt="forward" />
                </p>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-4 col-sm-12 col-lg-4 col-xl-4 mb-3 mb-md-0"
            >
              <div class="existing_prop_btn">
                <button class="btn" @click="topNavigation(1)">
                  <img src="../assets/images/renewalicon.svg" alt="renew" />
                  Existing Property & Rent Renewals
                </button>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-12 col-lg-4 col-xl-4">
              <div class="add_prop_rentfinancing_btn">
                <button class="btn" @click="topNavigation(2)">
                  <img src="../assets/images/addwhite-icon.svg" alt="add" />
                  Add New Property
                </button>
              </div>
            </div>
          </div>
          <!-- <div class="top_bar">
            <div>
              <p>
                Pay My Rent
                <img src="../assets/images/white-arrow.svg" alt="forward" />
              </p>
            </div>
            <button class="btn" @click="topNavigation(1)">
              <img src="../assets/images/renewalicon.svg" alt="renew" />
              Existing Property & Rent Renewals
            </button>
            <button class="btn" @click="topNavigation(2)">
              <img src="../assets/images/addwhite-icon.svg" alt="add" />
              Add New Property (Rent Financing)
            </button>
          </div> -->
        </div>

        <!-- Top blue box -->
        <div class="body-wrap container-fluid">
          <div class="row">
            <div class="col-md-6 col-lg-4 col-sm-12 col-lg-4 col-xl-4">
              <router-link to="/dashboard/safe">
                <div class="safe_activation">
                  <button class="activate_btn btn" @click="safeBtn">
                    <img src="../assets/images/safe.svg" alt="safe" />
                    SAFE
                  </button>
                </div>
              </router-link>
            </div>
            <!-- col-md-4 col-lg-4 -->
            <div class="col-md-6 col-lg-4 col-sm-12 col-lg-4 col-xl-4">
              <div class="rent-new-holder" @click="rentProp()">
                <div class="rent_new">
                  <div class="rent_home">
                    <p>Rent a new</p>
                    <h4>home</h4>
                    <p>Rent applications</p>
                  </div>
                  <div>
                    <img src="../assets/images/key-rent.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <!-- col-md-4 col-lg-4 -->
            <div class="col-md-6 col-lg-4 col-sm-12 col-lg-4 col-xl-4">
              <div class="top-option">
                <button
                  class="btn"
                  :disabled="paymentData.switchable != 'YES '"
                  @click="moveToSwitch(paymentData.nextpaymentid)"
                >
                  <div class="fisrt-top">
                    <p class="">
                      Finding it difficult to pay rent this time? Don’t lose
                      your peace
                    </p>
                    <div>
                      <img src="../assets/images/switch.svg" alt="Swicth" />
                      <img
                        class="swictIcon"
                        src="../assets/images/switch-icon.svg"
                        alt="Switch"
                      />
                    </div>
                    <p class="pmqb">
                      Pay Monthly, Quarterly, Bianually
                    </p>
                    <p class="tc">
                      T & C Applies
                    </p>
                  </div>
                </button>
              </div>
            </div>
            <!-- col-md-4 col-lg-4 -->
            <!-- <div class="col-md-6 col-lg-4 col-sm-12 col-lg-4 col-xl-4">
              <div class="top-option">
                <div class="second-top-option">
                  <div class="handyman-wrap">
                    <p>Request a</p>
                    <p class="">
                      Handy-man
                    </p>
                    <p>Facility Mangement</p>
                  </div>
                  <img src="../assets/images/maintain-facility.svg" alt="" />
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <!-- Top blue box end-->

        <!-- Second Card begins -->
        <div class="second-box container-fluid" v-if="paymentData">
          <div class="row">
            <div class="col-md-5">
              <div class="next-rent">
                <div class="next-rent-text">
                  <div class="nextrent">
                    <p>Next rent</p>
                    <p v-if="paymentData.arrayofnextpayment[0]">
                      {{ paymentData.arrayofnextpayment[0].daysleft }} days left
                    </p>
                  </div>
                </div>
                <!-- v-if="paymentData.nextpayment" -->
                <p v-if="paymentData.nextpayment">
                  &#8358;{{ Number(paymentData.nextpayment).toLocaleString() }}
                </p>
                <p>{{ paymentData.tenantmodeofpayments }}</p>

                <div class="pay-button">
                  <!-- data-toggle="modal"
                  data-target="#fundme" -->
                  <button
                    @click="trigerRentPayment(paymentData)"
                    :disabled="paymentData.nextpaymetdate === 0"
                    class="btn"
                  >
                    PAY NEXT RENT
                  </button>
                  <div class="due_date">
                    <p v-if="paymentData.nextpaymetdate | (moment === 0)">
                      Due date:
                      <span class="">{{
                        paymentData.nextpaymetdate | moment
                      }}</span>
                    </p>
                    <button
                      class="btn"
                      :disabled="
                        paymentData.switchable === 'NO' ||
                          paymentData.switchable === null
                      "
                      @click="moveToSwitch(paymentData.nextpaymentid)"
                    >
                      <img
                        class="switch2"
                        src="../assets/images/switch2.svg"
                        alt="switch"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Switch rent payment"
                      />
                      <img
                        src="../assets/images/switch-icon2.svg"
                        alt="switch"
                      />
                    </button>
                  </div>
                </div>
                <hr />

                <div class="last-paid">
                  <p>
                    Your last paid rent was
                  </p>
                  <h4 v-if="displayAmount === true">
                    &#8358;{{
                      Number(paymentData.lastpaymemt).toLocaleString()
                    }}
                  </h4>
                  <h4 v-if="displayAmount === false">xxxxxxxxxx</h4>

                  <div class="last-paid-date">
                    <p v-if="paymentData.lastpaymentdate | (moment === 0)"></p>
                    <p v-if="paymentData.lastpaymentdate">
                      {{ paymentData.lastpaymentdate | moment }}
                    </p>
                    <form class="form-inline">
                      <div
                        class="custom-control custom-radio mb-3 my-1 mr-sm-2"
                      >
                        <input
                          name="lastpay"
                          type="radio"
                          class="custom-control-input"
                          id="customControlInline3"
                          v-model="lastpayment"
                          value="show"
                          @change="showLastAmount"
                        />
                        <label
                          class="custom-control-label"
                          for="customControlInline3"
                          >Show</label
                        >
                      </div>
                      <div
                        class="custom-control custom-radio mb-3 my-1 mr-sm-2"
                      >
                        <input
                          name="lastpay"
                          type="radio"
                          class="custom-control-input"
                          id="customControlInline4"
                          v-model="lastpayment"
                          value="hide"
                          @change="showLastAmount"
                        />
                        <label
                          class="custom-control-label"
                          for="customControlInline4"
                          >Hide</label
                        >
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-7">
              <div class="profile-details table-responsive">
                <p>Profile details</p>
                <div class="container row-striped">
                  <div class="row">
                    <div class="col-md-4 col-sm-4 details-wrap">
                      <small>Your Shelta ID</small>
                      <p>{{ fulldata.user_object.usercode }}</p>
                    </div>
                    <div class="col-md-4 details-wrap">
                      <small>Average Income</small>
                      <p>{{ fulldata.financial_status }}</p>
                    </div>
                    <div class="col-md-4 details-wrap">
                      <small>Credit Score</small>
                      <p>0pts</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 details-wrap">
                      <small>Means of ID.</small>
                      <p>{{ fulldata.user_object.meansofidtype }}</p>
                    </div>
                    <div class="col-md-4 details-wrap">
                      <small> Email</small>
                      <p>{{ fulldata.user_object.email }}</p>
                    </div>
                    <div class="col-md-4 details-wrap">
                      <small>Employment status</small>
                      <p v-if="fulldata.employeddetails != null">Employed</p>
                      <p v-if="fulldata.selfemployed_details != null">
                        Self employed
                      </p>
                      <p v-if="fulldata.unemployed != null">Unemployed</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 details-wrap">
                      <small>Next of Kin</small>
                      <p v-if="fulldata.nextofkin != null">
                        {{ fulldata.nextofkin.fullname }}
                      </p>
                    </div>
                    <div class="col-md-4 details-wrap">
                      <small>In case of emergency</small>
                      <p v-if="fulldata.emergency_details != null">
                        {{ fulldata.emergency_details.phone }}
                      </p>
                    </div>
                    <div class="col-md-4 details-wrap">
                      <small>Phone</small>
                      <p>{{ fulldata.user_object.phone }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Second Card ends -->

        <!-- Third Card -->
        <div class="container-fluid">
          <div class="save-rent">
            <div class="row">
              <!-- <div class="col-md-4">
                <div class="save-rent-holder">
                  <p>Save your Rent</p>
                  <img src="../assets/images/save-rent.svg" alt="tick" />
                  <p>Live in Peace</p>
                </div>
              </div> -->
              <div class="col-md-4 col-lg-4 col-sm-12 col-lg-4 col-xl-4">
                <div class="swap-rent-holder2">
                  <div>
                    <img src="../assets/images/blackluck.svg" alt="luck" />
                  </div>
                </div>
                <div class="top-option">
                  <div class="second-top-option">
                    <div class="handyman-wrap">
                      <p>Request a</p>
                      <p class="">
                        Handy-man
                      </p>
                      <p>Facility Mangement</p>
                    </div>
                    <img src="../assets/images/maintain-facility.svg" alt="" />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="swap-rent-holder2">
                  <div>
                    <img src="../assets/images/blackluck.svg" alt="luck" />
                  </div>
                </div>
                <div class="swap-rent-holder">
                  <p>Need to move from your current rental?</p>
                  <img src="../assets/images/swap-color.svg" alt="swap" />
                  <p>Now you can swap an apartment for another</p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="swap-rent-holder2">
                  <div>
                    <img src="../assets/images/blackluck.svg" alt="luck" />
                  </div>
                </div>
                <div class="rent-toown-holder">
                  <div class="rent-own">
                    <h4>
                      Rent to own
                    </h4>
                    <div class="own-rent2">
                      <p>Monthly contribution <span>&#8358;0</span></p>
                      <p>Arrears <span>&#8358;0</span></p>
                      <p>Ballance <span>&#8358;0</span></p>
                      <h5>Total Amount Due <span>&#8358;0</span></h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Third card ends -->

        <!-- Property table -->
        <div class="propertt-list-holder">
          <div class="container">
            <div class="property-text-wrap">
              <p>Listed Properties Near You</p>
              <p>
                <router-link
                  to="/propertylist"
                  style="color: inherit; text-decoration: none;"
                >
                  View all listing
                </router-link>
              </p>
            </div>
          </div>
          <div class="table-responsive prop_table_wrap">
            <table class="table table-striped">
              <thead>
                <tr class="table-row table-head">
                  <th scope="col">PIN</th>
                  <th scope="col">IMAGE</th>
                  <th scope="col">TITLE</th>
                  <th scope="col">LOCATION</th>
                  <th scope="col">DESCRIPTION</th>
                  <th scope="col">BED</th>
                  <th scope="col">BATH</th>
                  <th scope="col">TOILET</th>
                  <th scope="col">PARKING</th>
                  <th scope="col">RATE</th>
                </tr>
              </thead>
              <tbody v-for="(property, index) in properties" :key="index">
                <tr class="table-row">
                  <td>PIN: {{ property.pin }}</td>
                  <td>
                    <img
                      class="prop-img-list"
                      :src="property.images[0]"
                      alt="income"
                    />
                  </td>
                  <td>{{ property.title }}</td>
                  <td>{{ property.city }}, {{ property.state }}</td>
                  <td>
                    {{ property.description }}
                  </td>
                  <td>{{ property.bedrooms }}</td>
                  <td>{{ property.bathrooms }}</td>
                  <td>{{ property.toilets }}</td>
                  <td>{{ property.parking }}</td>
                  <td>&#8358;{{ Number(property.price).toLocaleString() }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Property table -->
      </div>
    </div>
    <div class="sticky-button" v-if="subscribed == 'NO'">
      <button @click="membership" class="btn">Become a Shelta User</button>
    </div>
    <div v-if="loader">
      <PageLoader></PageLoader>
    </div>

    <!-- <component class="more-content" :is="PaymentModal"></component> -->
    <PaymentModal
      @paystackReference="dataFromChild($event)"
      @action="dataFromChild($event)"
      :dataForPayment="modalData"
      :responseData="resData"
      v-model="paymentM"
    ></PaymentModal>
    <!-- <component class="" :header="headerCaption" :is="PaymentModal"></component> -->
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import { Service } from "../store/service";
import moment from "moment";
import $ from "jquery";
const Api = new Service();
import PaymentModal from "./PaymentModal.vue";
import PageLoader from "./PageLoader.vue";
// import PromoGif from "../components/PromoGif.vue";
import paystack from "vue-paystack";
import { staticdata } from "../../src/data";
export default {
  name: "TenantDashboard",
  components: {
    SmallNav,
    paystack,
    PaymentModal,
    PageLoader
    // PromoGif
  },
  data() {
    return {
      loader: false,
      one: "",
      two: "",
      three: "",
      four: "",
      five: "",
      uploadFile: "",
      properties: [],
      propertyPin: "",
      user: "",
      id: "",
      amountpaid: "",
      lastpayment: "hide",
      displayAmount: false,
      moreDetails: false,
      fulldata: "",
      error: false,
      uploading: false,
      fileselected: false,
      filePath: "",
      progress: 0,
      file: "",
      headerText1: true,
      headerText2: false,
      headerText3: false,
      userid: "",
      percentageFull: false,
      paymentData: "",
      // daysleft: "0",
      fulldata: {
        user_object: {}
      },
      // PUBLIC_KEY: staticdata.paystackkeytest,
      // PUBLIC_KEY: staticdata.paystackkeylive,
      email: "",
      full_name: "",
      reference: "",
      modalData: {},
      paymentM: "",
      subscribed: "",
      resData: ""
      // lastpayment: "show",
    };
  },
  created() {
    this.$root.$refs.A = this;
    this.$root.$refs.C = this;
  },
  beforeMount() {},
  mounted() {
    this.checkLogger();
    this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    this.email = this.user.email;
    this.full_name = this.user.firstname + this.user.lastname;
    this.fetchProperties();
    this.tenantDetails();
    this.getUserProfile();
    this.moreTenantData();
    this.subscribed = JSON.parse(
      localStorage.getItem("shelta-user")
    ).subscribed;
  },
  methods: {
    checkLogger() {
      Api.postRequest(`logcounter`, {})
        .then(res => {
          // console.log("Who reads", res);
        })
        .catch(err => {
          console.log(err);
          this.loader = false;
        });
    },
    membership() {
      this.$root.$refs.D.openSubModal();
    },
    MoveToSure() {
      this.$router
        .push({
          path: `/sure`
        })
        .catch(() => {});
    },
    trigerRentPayment: function(propInfo) {
      this.fundLoader = true;
      this.modalData.amount = 0;
      this.modalData.payableAmount = Number(this.paymentData.nextpayment);
      this.modalData.subtitle = "Payment Amount";
      this.modalData.paymentType = "rentpayment";
      this.modalData.headerCaption = "RENT PAYMENT";
      this.modalData.headerBackground = "#0033ea";
      // this.modalData.paymentURL = "";
      this.$root.$refs.A.openPaymentOptionsModal(propInfo);
    },
    dataFromChild(data) {
      // console.log("Data from child", data);
      if (
        data.action === true &&
        this.modalData.paymentType === "rentpayment" &&
        data.type === "paystack"
      ) {
        this.processPaymentPaystck(data.ref);
      }
      if (
        data.action === true &&
        this.modalData.paymentType === "rentpayment" &&
        data.type === "payfromsafe"
      ) {
        this.payMyRentWithSafe();
      }
      // if ( data.action === true &&
      //   this.modalData.paymentType === "rentpayment" &&
      //   data.type === "paybanktransferfromsafe") {}
      // this.reference = data;
      // this.setupScheduleFinally();
    },
    // If pay from safe is selected
    async payMyRentWithSafe() {
      // alert("pay with safe");
      this.resData = "loading";
      this.loader = true;
      if (
        this.paymentData.nextpaymentid === "" ||
        this.paymentData.nextpayment === ""
      ) {
        this.loader = false;
        return this.$store.commit("setApiFailed", "Payment parameters missing");
      } else {
        try {
          // payrentviasafe
          await Api.postRequest(`paynowwithsafe`, {
            id: this.paymentData.nextpaymentid,
            amount: Number(this.paymentData.nextpayment)
          })
            .then(res => {
              // console.log("Rent Payment from safe response", res);
              this.resData = "response";
              this.loader = false;
              if (res.data.success) {
                this.moreTenantData();
                return this.$store.commit(
                  "setApiSuccess",
                  "Payment is successful"
                );
              }
              this.resData = "";
              if (res.data.error) {
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              this.loader = false;
              this.resData = "response";
              console.log("err", err);
              // return this.$store.commit("setApiFailed", "Error occured");
            });
        } finally {
          // console.log("closed")
        }
      }
    },
    closedPaystack() {
      this.loader = false;
    },
    processPaymentPaystck(res) {
      this.loader = true;
      const ref = res;
      if (
        this.paymentData.nextpaymentid === "" ||
        this.paymentData.nextpayment === "" ||
        ref == ""
      ) {
        return this.$store.commit("setApiFailed", "Payment data missing");
      }
      Api.postRequest(`paynow`, {
        id: this.paymentData.nextpaymentid,
        amount: this.paymentData.nextpayment,
        referencecode: ref
      })
        .then(res => {
          // console.log("Payment done response", res);
          this.loader = false;
          if (res.data.success === "success") {
            this.moreTenantData();
            return this.$store.commit("setApiSuccess", "Payment is successful");
          }
          if (res.data.error) {
            return this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          console.log("err", err);
          this.loader = false;
          // return this.$store.commit("setApiFailed", "Error occured");
        });
      // console.log("refrence key", res, ref);
      // window.alert("Refrence key:", this.reference);
    },
    // Paystack close function or cancel payment
    close: () => {
      console.log("You closed checkout page");
      // this.fundLoader = false;
    },
    moveToSwitch: function(id) {
      if (id) {
        this.$router
          .push({
            path: `/dashboard/switch/${id}`
          })
          .catch(() => {});
      }
    },
    topNavigation(data) {
      if (data === 1) {
        this.$gtag.event("rented-property-initial-click", {
          event_category: "dashboard documentaion clicks",
          event_label: "Existing Property & Rent Renewal Button Clicked",
          value: 1
        });
        this.$router
          .push({
            path: `/dashboard/rented`
          })
          .catch(() => {});
      } else if (data === 2) {
        this.$gtag.event("add-property-rented-initial-click", {
          event_category: "dashboard documentaion clicks",
          event_label: "Add a New Property Button Clicked",
          value: 1
        });
        this.$router
          .push({
            path: `/dashboard/addpropertytenant`
          })
          .catch(() => {});
      }
    },
    safeBtn() {
      this.$gtag.event("safe-initial-click", {
        event_category: "dashboard documentaion clicks",
        event_label: "Safe Button Clicked",
        value: 1
      });
    },

    payModal: function() {
      $("#propertyPin")
        .removeClass("fade")
        .modal("hide");
      $("#showpfundmeayment")
        .addClass("fade")
        .modal("show");
    },
    moreTenantData: function() {
      Api.getRequest(`fetchmoretenantdatabyid/${this.id}`)
        .then(res => {
          // console.log("More data", res);
          this.paymentData = res;
          this.paymentData.data.arrayofnextpayment.map(element => {
            const rentDueDate = element.duedate;
            // console.log("rented date", rentDueDate);
            const rentdate = new Date(rentDueDate);
            const currentDate = new Date();
            const timeDiff = rentdate.getTime() - currentDate.getTime();
            const diff = timeDiff / (1000 * 3600 * 24);
            const daysDiff = Math.round(diff);
            if (daysDiff <= 0) {
              element.daysleft = 0;
            } else {
              element.daysleft = parseInt(daysDiff);
            }
            // console.log("checkkkk", parseInt(daysDiff));
          });
          window.localStorage.setItem(
            "currentpropertydetails",
            JSON.stringify(res.data)
          );
          this.paymentData = JSON.parse(
            window.localStorage.getItem("currentpropertydetails")
          );
          // console.log("datttttttttt", this.paymentData);
        })
        .catch(err => {
          // console.log(err);
          // this.loader = false;
          this.uploading = true;
        });
    },
    getUserProfile: function() {
      Api.getRequest(`getuserbyid/${this.id}`).then(res => {
        // console.log("User profile", res);
        if (res.status == 401) {
          window.localStorage.clear();
          return (window.location.href = "https://myshelta.com/login");
        }
        this.user = res.data.user;
        this.fulldata = res.data.fulldata;
        // console.log("full data", this.user);
        // this.emergency = this.fulldata.emergency_details;
      });
    },
    // switchView(event) {
    //   if (event === 1) {
    //     document.getElementById("onee").style.display = "none";
    //     document.getElementById("twoo").style.display = "block";
    //     document.getElementById("threee").style.display = "none";
    //     this.headerText1 = false;
    //     this.headerText2 = true;
    //     this.headerText3 = false;
    //   } else if (event === 2) {
    //     document.getElementById("onee").style.display = "none";
    //     document.getElementById("twoo").style.display = "none";
    //     document.getElementById("threee").style.display = "block";
    //     this.headerText1 = false;
    //     this.headerText2 = false;
    //     this.headerText3 = true;
    //   } else if (event === 0) {
    //     document.getElementById("onee").style.display = "block";
    //     document.getElementById("twoo").style.display = "none";
    //     document.getElementById("threee").style.display = "none";
    //     // this.headerText2 = false;
    //   } else {
    //     document.getElementById("twoo").style.display = "none";
    //     document.getElementById("onee").style.display = "none";
    //     document.getElementById("threee").style.display = "block";
    //   }
    // },

    // selectFile() {
    //   const file = this.$refs.file.files[0];
    //   this.file = file;
    //   this.filePath = URL.createObjectURL(file);
    //   this.fileName = file.name;
    //   // console.log("fileeeeeeeeeeee", this.file);
    //   if (this.file) {
    //     this.fileselected = true;
    //   } else {
    //     this.fileselected = false;
    //   }
    // },
    // async sendFile() {
    //   // this.switchView(2)
    //   const file = this.$refs.file.files[0];
    //   // this.file = file;
    //   if (!this.file) {
    //     // this.loaderM = false;
    //     return this.$store.commit("setApiFailed", "No file was selected!");
    //   }
    //   const formData = new FormData();
    //   formData.append("file", this.file);
    //   formData.append("amount", this.paymentData.nextpayment);
    //   formData.append("id", this.paymentData.nextpaymentid);
    //   try {
    //     this.uploading = true;
    //     await Api.postRequest(`paynow`, formData, {
    //       onUploadProgress: e =>
    //         (this.progress = Math.round((e.loaded * 100) / e.total))
    //     })
    //       .then(res => {
    //         // console.log("Uploaded file", res);
    //         // this.$store.commit("setApiWarning", '');
    //         this.$store.commit("setApiSuccess", "Success");
    //         this.switchView(2);
    //         this.uploading = false;
    //         if (res.data.success) {
    //           this.moreTenantData();
    //         }
    //         // this.message = "File has been uploaded";
    //         // this.file = '';
    //         // this.error = false;
    //       })
    //       .catch(err => {
    //         // console.log(err);
    //         // this.loader = false;
    //         this.uploading = true;
    //       });
    //   } finally {
    //     // console.log("Closed connection");
    //   }
    // },
    tenantDetails: async function() {
      try {
        await Api.getRequest(`fetchmoretenantdatabyid/${this.id}`)
          .then(res => {
            // console.log("Details", res);
            this.moreDetails = res.data;
          })
          .catch(err => {
            // console.log(err);
            this.loader = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    rentProp: function(id) {
      this.$router.push({
        path: `/dashboard/rent/${id}`
      });
    },
    showLastAmount: function() {
      if (this.lastpayment === "show") {
        this.displayAmount = true;
      } else {
        this.displayAmount = false;
      }
    },
    computedClass(index) {
      return "striped_" + Math.floor((index + 1) % 4);
    },
    fetchProperties: async function() {
      try {
        await Api.getRequest(`fetchpropertiesrandomly`).then(res => {
          // console.log("Property List", res);
          // this.properties = res.data.properties;
          this.$store.commit("setApiWarning", "Loading properies...");

          if (res.data.success) {
            this.$store.commit("setApiWarning", "");
            const cool = res.data.properties;
            const x = cool.slice(0, 7);
            this.properties = x;
            // console.log("sliced array", this.properties);
            // let member = "my name is Charles";
            // let last2 = member.slice(-2);
            // console.log("last twomin array", last2)
            this.$store.commit("setApiSuccess", "Propeties loaded");
          } else if (res.data.error) {
            return this.$store.commit("setApiFailed", res.data.error);
          }
        });
      } finally {
        // console.log("Closed connection");
      }
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$black: #000000;
@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}
// $fontFamily: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");
// $fontFamily2: url("https://fonts.googleapis.com/css2?family=Gotham-Ultra:wght@100&display=swap");
// .modal.in {
//   -webkit-transform: translateZ(0);
//   transform: translateZ(0);
// }

.sticky-button {
  position: fixed;
  right: 2%;
  bottom: 3%;
  z-index: 10;
  button {
    width: 240px;
    height: 64px;
    color: #ffffff;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    border-radius: 40px;
    background: #0033ea;
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04),
      0px 20px 25px -5px rgba(0, 0, 0, 0.1);
  }
}
@media screen and (max-width: 599px) {
  .sticky-button {
    button {
      width: 186px;
      height: 46px;
    }
  }
}

.dashboard-holder {
  padding-bottom: 35px;
}

.modal-open {
  overflow: scroll !important;
}

.top_bar_payrent {
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 44px;
  padding-right: 20px;
  color: $secondary;
  margin-bottom: 0;
}
.existing_prop_btn {
  button {
    background: $secondary;
    color: $primary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 30px;
    max-width: 340px;
    width: 100%;
    img {
      padding-right: 20px;
    }
  }
}

.add_prop_rentfinancing_btn {
  button {
    background: #2fc643;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 30px;
    max-width: 324px;
    width: 100%;
    display: flex;
    align-items: center;
    img {
      padding-right: 16px;
      padding-left: 0px;
    }
  }
}

.top_bar_holder {
  padding: 15px;
  // position: relative;
  background: linear-gradient(180.48deg, #000000 -92.86%, #0033ea 130.64%),
    #ffffff;
  border-radius: 10px 10px 0 0;
  margin: 0 20px;
  // position: fixed;
  width: -webkit-fill-available;
  max-width: 100%;
  top: 54px;
  z-index: 10;
  margin: 1rem;
  // margin-top: 3rem;
}

// .small_nav_holder {
//   margin-top: 5rem;
// }

.top_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  div:first-child {
    p {
      font-family: Gotham;
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 30px;
      padding-right: 20px;
      color: $secondary;
      margin-bottom: 0;
    }
  }
  button:nth-child(2) {
    background: $secondary;
    color: $primary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 30px;
    max-width: 340px;
    width: 100%;
    img {
      padding-right: 20px;
    }
  }
  button:nth-child(3) {
    background: #2fc643;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 30px;
    max-width: 324px;
    width: 100%;
    display: flex;
    align-items: center;
    img {
      padding-right: 16px;
      padding-left: 0px;
    }
  }
}

.body-wrap {
  // padding-top: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 2rem;
}

.safe_activation {
  position: relative;
  background: linear-gradient(
    90deg,
    #0434df 40.15%,
    rgba(0, 119, 255, 0.6) 173.98%
  );
  // background-image: url("../assets/images/blue-card-safe.svg");
  background-repeat: no-repeat;
  // height: 100%;
  min-height: 154px;
  // background-size: cover;
  // height: 100vh;
  border-radius: 10px;
  display: grid;
  margin-bottom: 1rem;
}

.activate_btn {
  border: 1px solid $secondary !important;
  border-radius: 5px;
  background: transparent;
  color: $secondary;
  font-family: Gotham;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 25px;
  box-shadow: none;
  outline: 0;
  margin: 58px 80px;
  img {
    padding-right: 20px;
  }
}

.rent-new-holder {
  background: #ffffff;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  min-height: 154px;
  margin-bottom: 1rem;
  cursor: pointer;
}

.top-option {
  background: linear-gradient(181.56deg, #0033ea -8.75%, #014561 102.5%);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 154px;
}

button:disabled {
  cursor: not-allowed;
  background: rgba(255, 255, 255, 0.81);
  height: inherit;
  border-radius: 10px;
}

.fisrt-top {
  p:first-child {
    padding: 0.3rem 4rem 0 1rem;
    text-align: start;
    margin-bottom: 0;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    // display: flex;
    align-items: center;
    color: $secondary;
  }
  div {
    padding: 0 2rem 0 1rem;
    display: flex;
    justify-content: space-between;
  }
}

.rent_new {
  // text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
}
.rent_home {
  position: relative;
  text-align: left;
  p:first-child {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    color: $black;
    margin-bottom: 0;
    position: relative;
    top: 8px;
  }
  h4:nth-child(2) {
    font-family: Gotham-bold;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 35px;
    color: $black;
  }
  p:nth-child(3) {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    color: $black;
    padding-top: 25px;
  }
}
// .rent-new > h4 {
//   font-family: $fontFamily;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 23px;
//   line-height: 29px;
//   text-align: start;
//   align-items: center;
//   color: $black;
//   padding: 60px 0;
//   cursor: pointer;
//   text-align: center;
// }
.swictIcon {
  width: 4rem;
}
.pmqb {
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 11px;
  text-align: start;
  // display: flex;
  align-items: center;
  color: $secondary;
  padding-left: 1rem;
  margin-bottom: 0;
}
.tc {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 27px;
  text-align: start;
  padding-left: 1rem;
  align-items: center;
  color: #ffffff;
  padding-bottom: 1rem;
}
.second-top-option {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}
.handyman-wrap {
  padding-top: 1rem;
  padding-bottom: 0rem;
}
.handyman-wrap > p:first-child {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 26px;
  text-align: start;
  // display: flex;
  align-items: center;
  color: $secondary;
}
.handyman-wrap > p:nth-child(2) {
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 23px;
  text-align: start;
  // display: flex;
  align-items: center;
  color: $secondary;
}
.handyman-wrap > p:nth-child(3) {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 46px;
  text-align: start;
  align-items: center;
  color: $secondary;
  margin-bottom: 0;
}

.rent-own {
  padding: 1rem;
}
.rent-own > h4 {
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 29px;
  text-align: start;
  align-items: center;
  color: $secondary;
}
.own-rent {
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $secondary;
  }
  h5 {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $secondary;
  }
}
.switch2 {
  padding-right: 5px;
}
.next-rent {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.next-rent-text {
  // display: flex;
  // justify-content: space-between;
  // padding: 2rem 2rem 0 2rem;
  position: relative;
}
.nextrent {
  position: absolute;
  background: linear-gradient(180.48deg, #000000 -92.86%, #0033ea 130.64%),
    #ffffff;
  right: 3%;
  border-radius: 0px 0px 10px 10px;
  padding: 10px;

  p:first-child {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    margin-bottom: 5px;
    color: $secondary;
  }
  p:nth-child(2) {
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 11px;
    margin-bottom: 0;
    color: $secondary;
  }
}

.next-rent-text > p:first-child {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 11px;
  text-align: start;
  // display: flex;
  align-items: center;
  color: $black;
}
.next-rent-text > p:nth-child(2) {
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 11px;
  display: flex;
  align-items: center;
  color: $black;
}
.next-rent > p:nth-child(2) {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 33px;
  text-align: start;
  padding-left: 1rem;
  padding-top: 3.2rem;
  margin-bottom: 5px;
  // display: flex;
  align-items: center;
  color: $black;
}
.next-rent > p:nth-child(3) {
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  text-align: start;
  padding-left: 1rem;
  color: $black;
}
.pay-button {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0rem 1rem;
  align-items: baseline;
}
.due_date {
  position: relative;
  button {
    height: 45px !important;
    // height: 100%;
  }
}
.pay-button {
  button {
    background: linear-gradient(181.56deg, #0033ea -8.75%, #014561 102.5%);
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 5px;
    outline: 0;
    box-shadow: none;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 19px;
    height: 45px;
    // height: 100%;
    // display: flex;
    align-items: center;
  }
}
.pay-button {
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    // line-height: 40px;
    align-items: end;
    text-align: right;
    color: $black;
    margin-bottom: 0;
    position: absolute;
    top: -58%;
    left: 3%;

    // span {
    //   font-weight: bold;
    //   padding-left: 0.5rem;
    // }
  }
}
.last-paid {
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 11px;
    // display: flex;
    text-align: start;
    align-items: center;
    color: $black;
    padding: 1rem 0 0 1rem;
  }
  h4 {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 33px;
    text-align: start;
    // display: flex;
    align-items: center;
    color: $black;
    padding: 1rem 0 1rem 1rem;
  }
  h6 {
    font-family: Gotham;
    font-size: 12px;
  }
}

.last-paid-date {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  // padding-top: 2rem;

  form {
    padding-right: 1rem;
    padding-top: 0.5rem;
  }
}

.profile-details > p:first-child {
  // background: $secondary;
  background: linear-gradient(180.48deg, #000000 -92.86%, #0033ea 130.64%),
    #ffffff;
  border-radius: 10px 10px 0px 0px;

  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 44px;
  text-align: start;
  padding-left: 2rem;
  align-items: center;
  color: $secondary;
  margin-bottom: 0;
}
.profile-details {
  background: $secondary;
  border-radius: 10px 10px 0 0;
  padding-bottom: 1rem;
}

.details-wrap {
  small {
    font-family: Gotham;
    font-style: italic;
    font-weight: 100;
    font-size: 14px;
    line-height: 49px;
    /* display: flex; */
    align-items: center;
    color: $black;
    padding-top: 1rem;
    white-space: pre;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    // display: flex;
    text-align: center;
    align-items: center;
    color: $black;
    white-space: pre;
    margin-bottom: 0;
  }
}

.striped_1 {
  background: #efefef;
}
.striped_2 {
  background: $secondary;
}

.row-striped {
  .row:first-child {
    background: #efefef;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
  }
  .row:nth-child(2) {
    background: $secondary;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .row:nth-child(3) {
    background: #efefef;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.save-rent {
  padding-top: 2rem;
}
.save-rent-holder {
  background: #ffffff;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 154px;
  margin-bottom: 2rem;
}
.save-rent-holder {
  p:first-child {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 23px;
    text-align: center;
    align-items: center;
    color: $black;
    padding-top: 1rem;
  }
}

.save-rent-holder {
  p:nth-child(3) {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 11px;
    text-align: center;
    align-items: center;
    padding-top: 0.5rem;
    color: $black;
  }
}

.swap-rent-holder {
  background: linear-gradient(181.56deg, #0033ea -8.75%, #014561 102.5%);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 154px;
  margin-bottom: 2rem;
}
.swap-rent-holder2 {
  background: rgba(255, 255, 255, 0.81);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 154px;
  margin-bottom: 2rem;
  position: absolute;
  // width: 347px;
  width: calc(100% - 30px);
  div {
    padding-top: 3rem;
  }
}

.swap-rent-holder {
  p:first-child {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
    text-align: center;
    align-items: center;
    color: $secondary;
    padding-top: 1rem;
  }
}
.swap-rent-holder {
  p:nth-child(3) {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 105.2%;
    text-align: center;
    align-items: center;
    text-align: center;
    color: $secondary;
    padding: 0.5rem;
  }
}

// Rent to own
.rent-toown-holder {
  background: linear-gradient(180deg, #0033ea 0%, #014561 102.6%), #ffffff;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 154px;
  margin-bottom: 2rem;
}

.own-rent2 {
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $secondary;
  }
  h5 {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $secondary;
  }
}
// Rent to own

.property-text-wrap {
  display: flex;
  justify-content: space-between;
}
.propertt-list-holder {
  background: #fdfdfd;
  border-radius: 10px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin: 2rem 1rem;
}
.property-text-wrap {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}
.property-text-wrap > p:nth-child(2) {
  cursor: pointer;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 11px;
  text-align: right;
  align-items: center;
  text-decoration-line: underline;
  color: $primary;
}
.property-text-wrap > p:first-child {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  text-align: start;
  align-items: center;
  color: $black;
}

.prop_table_wrap {
  // padding-bottom: 40px;
  margin-bottom: 40px;
}

.prop-img-list {
  width: 4rem;
  height: 2rem;
  border-radius: 5px;
  object-fit: cover;
}

.table-head {
  th {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    text-align: start;
    align-items: center;
    color: $black;
  }
}
.table-row {
  td {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: $black;
    text-align: start;
  }
}

.custom-control-label {
  font-family: Gotham;
}

@media screen and (max-width: 599px) {
  .top_bar {
    display: block;
    div:first-child {
      display: none;
    }
    button:nth-child(2) {
      display: flex;
      text-align: left;
      align-items: center;
      line-height: 20px;
      margin-bottom: 20px;
      img {
        padding-right: 10px;
      }
    }
    button:nth-child(3) {
      img {
        padding-right: 0px;
        padding-left: 0px;
      }
      line-height: 20px;
    }
  }

  .existing_prop_btn {
    button {
      line-height: 20px;
      img {
        padding-right: 10px;
      }
    }
  }
  .add_prop_rentfinancing_btn {
    button {
      line-height: 20px;
      img {
        padding-right: 7px;
        padding-left: 0px;
      }
    }
  }

  .top-option {
    margin-bottom: 1rem;
  }

  .handyman-wrap > p:nth-child(2) {
    font-size: 30px;
  }
  .second-box {
    .row {
      div {
        margin-bottom: 1rem;
      }
    }
  }
  .form-inline {
    div:first-child {
      padding-right: 1rem;
    }
  }
  .row-striped {
    div {
      // flex-wrap: nowrap;
      flex-wrap: wrap;
      overflow: auto;
    }
  }
  .row-striped {
    .row:first-child {
      background: #efefef;
      padding-top: 10px;
      padding-bottom: 0;
    }
    .row:nth-child(2) {
      background: $secondary;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .row:nth-child(3) {
      background: #efefef;
      padding-top: 10px;
      padding-bottom: 0;
    }
  }
  .next-rent > p:nth-child(2) {
    font-size: 28px;
  }
  .save-rent {
    padding-top: 0;
  }
  .last-paid {
    h4 {
      font-size: 28px;
    }
  }
  .table-row > td {
    white-space: pre !important;
  }
  .pay-button {
    button {
      font-size: 12px;
    }
  }
  .details-wrap {
    small {
      white-space: pre-line;
      line-height: 0px;
    }
  }
  .last-paid-date {
    display: block;
    form {
      padding-left: 1rem;
    }
  }

  .swap-rent-holder2 {
    width: 330px;
  }
}
</style>
